import { ElementHandle, Page } from '@playwright/test'

export const TEST_ATTRIBUTE = 'data-testid'

export const testURL = 'http://localhost:8000'

export const TEST_IDS = {
    ageGate: {
        container: 'ageGateContainer',
        year: 'ageGateYear',
        month: 'ageGateMonth',
        day: 'ageGateDay',
        submit: 'ageGateSubmit',
        blockedMessage: 'ageGateBlockedMessage',
        title: 'ageGateTitle'
    },
    error: {
        header: 'errorHeader',
        message: 'errorMessage'
    },
    footerSite: {
        companyLinks: 'footerSiteCompanyLinks',
        franceOnlyMessage: 'footerSiteFranceOnlyMessage',
        franchiseLink: 'footerSiteFranchiseLink',
        companyLink: 'footerSiteCompanyLink',
        twitterLink: 'footerSiteTwitterLink',
        facebookLink: 'footerSiteFacebookLink',
        twitchLink: 'footerSiteTwitchLink',
        youtubeLink: 'footerSiteYoutubeLink',
        instagramLink: 'footerSiteInstagramLink'
    },
    imgCarousel: {
        backButton: 'carouselBackButton',
        nextButton: 'carouselNextButton',
        displayedImage: 'carouselDisplayedImage'
    },
    youtubeEmbedSwap: {
        link: 'youtubeEmbedSwapLink',
        icon: 'youtubeEmbedSwapIcon',
        button: 'youtubeEmbedSwapButton'
    }
}

export const querySelector = (id: string): string => {
    return `[${TEST_ATTRIBUTE}="${id}"]`
}

export const getByTestId = async (page: Page, id: string): Promise<ElementHandle<SVGElement | HTMLElement> | null> => {
    return await page.$(querySelector(id))
}

export const getAllByTestId = async (page: Page, id: string): Promise<ElementHandle<SVGElement | HTMLElement>[]> => {
    return await page.$$(querySelector(id))
}

export default TEST_IDS
