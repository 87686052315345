import React, { forwardRef } from 'react'
import get from 'tcweb-material-components/core/poly/get'
import isString from 'lodash/isString'

import Link from '../../common/link'

const listStrings = (element) =>
	React.Children.map(element, (child) => {
		if (isString(child)) return child

		if (!get(child, 'props.children')) return child

		return React.cloneElement(child, {
			...child.props,
			children: listStrings(child.props.children)
		})
	})

const MobileMenuItem = forwardRef(({ children, ...rest }, ref) => {
	return (
		<Link {...rest} ref={ref}>{listStrings(children)}</Link>
	)
})

export default MobileMenuItem
