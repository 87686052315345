import React from 'react'
import PropTypes from 'prop-types'
import get from 'tcweb-material-components/core/poly/get'
import { makeStyles, useTheme } from '@material-ui/styles'
import Box from 'tcweb-material-components/core/box'
import classnames from 'classnames'

import Header from '../header'
import Footer from '../footer'
import AgeGate from '../modal/ageGate'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        minHeight: '100vh',
        flexDirection: 'column'
    },
    vetricalGuttersPage: {
        paddingTop: '50px',
        paddingBottom: '50px',
        [theme.breakpoints.up('md')]: {
            paddingTop: '100px',
            paddingBottom: '100px'
        }
    },
    main: {
        display: 'flex',
        flexDirection: 'column',
        flex: '1 1 auto'
    },
    fullPageWidth: {
        marginLeft: -theme.gutters.page.xs,
        width: `calc(100% + (${theme.gutters.page.xs} * 2))`,
        maxWidth: `calc(100% + (${theme.gutters.page.xs} * 2))`,

        [theme.breakpoints.only('sm')]: {
            marginLeft: -theme.gutters.page.md,
            width: `calc(100% + (${theme.gutters.page.md} * 2))`,
            maxWidth: `calc(100% + (${theme.gutters.page.md} * 2))`
        },
        [theme.breakpoints.up('md')]: {
            marginLeft: -theme.gutters.page.lg,
            width: `calc(100% + (${theme.gutters.page.lg} * 2))`,
            maxWidth: `calc(100% + (${theme.gutters.page.lg} * 2))`
        }
    },
    postPageWidth: {
        paddingRight: theme.gutters.page.xs,
        paddingLeft: theme.gutters.page.xs,
        [theme.breakpoints.only('sm')]: {
            paddingRight: theme.gutters.page.md,
            paddingLeft: theme.gutters.page.md
        },
        [theme.breakpoints.up('md')]: {
            padding: 0,
            margin: 'auto auto',
            width: '50%'
        },
        '& .alignfull': {
            [theme.breakpoints.up('md')]: {
                marginLeft: '-50%',
                width: 'calc(100% + 100%)',
                maxWidth: 'calc(100% + 100%)'
            }
        },
        '& .alignwide': {
            [theme.breakpoints.up('md')]: {
                marginLeft: `calc(-50% + ${theme.gutters.page.lg})`,
                width: `calc(100% + (100%) - 2*${theme.gutters.page.lg})`,
                maxWidth: `calc(100% + (100%) - 2*${theme.gutters.page.lg})`
            }
        }
    }
}))

const LayoutContent = (props) => {
    const { className, children, pageWidth, setBackgroundImage, mainClass, hasVerticalGutters, pageContext } = props
    const classes = useStyles(props)
    const theme = useTheme()
    const pageClassName = get(pageContext, 'className')

    if (typeof window !== 'undefined') {
        document.body.style.setProperty('--bg-color', `${theme.palette.background.default}`)
    }

    return (
        <Box className={classnames(classes.root, className, { 'bg-default': setBackgroundImage })}>
            <Header {...props} />

            <Box
                className={classnames(
                    {
                        [classes.vetricalGuttersPage]: hasVerticalGutters,
                        [`page-${pageClassName}`]: !!pageClassName
                    },
                    props.main,
                    classes[`${pageWidth}PageWidth`],
                    mainClass
                )}
                component='main'
                tabIndex={-1}
                gutters={props.gutters || (mainClass !== 'wp-content' ? 'page' : null)}
            >
                {children}
            </Box>

            <Footer {...props} />

            <AgeGate />
        </Box>
    )
}

LayoutContent.defaultProps = {
    pageWidth: 'normal',
    setBackgroundImage: false
}

LayoutContent.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
    pageWidth: PropTypes.oneOf(['normal', 'full', 'post']),
    setBackgroundImage: PropTypes.bool
}

export default LayoutContent
