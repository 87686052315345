import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Linkedin from 'tcweb-material-components/core/social/linkedin'
import Twitter from 'tcweb-material-components/core/social/twitter'
import Divider from '@material-ui/core/Divider'

import TEST_IDS from '../../constants/query-selectors'
import { Typography } from '@material-ui/core'

const TABLET_SPACING = '30px'
const MOBILE_SPACING = '20px'
const ITEM_GAP = 2.5

const useStyles = makeStyles((theme) => ({
    highContrast: {
        ...theme.accessibility.highContrast.blackOnWhite
    },
    section: {
        display: 'flex',
        alignItems: 'center',
        paddingTop: MOBILE_SPACING,
        paddingBottom: MOBILE_SPACING,
        borderBottom: `1px solid ${theme.palette.border.primary}`,
        '& a': {
            width: 'auto',
            marginRight: ITEM_GAP,
            '&:last-of-type': {
                marginRight: 0
            }
        },
        [theme.breakpoints.up('sm')]: {
            paddingTop: TABLET_SPACING,
            paddingBottom: TABLET_SPACING
        }
    },
    siteLink: {
        [theme.breakpoints.up('md')]: {
            fontSize: '1.1em'
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '1.33em'
        }
    },
    franchiseLinks: {
        display: 'grid',
        gridRowGap: MOBILE_SPACING,
        gridTemplateColumns: '1fr 1fr',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            flexWrap: 'wrap'
        }
    },
    companyLinks: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        [theme.breakpoints.up('md')]: {
            // flexDirection: 'row',
            borderBottom: `1px solid ${theme.palette.border.primary}`
        },
        '& > nav': {
            [theme.breakpoints.up('md')]: {
                paddingTop: TABLET_SPACING,
                paddingBottom: TABLET_SPACING,
                borderBottom: 'none'
            }
        }
    },
    socialMediaLinks: {
        display: 'grid',
        gridGap: `${ITEM_GAP /2}em`,
        gridTemplateColumns: 'auto auto',
        justifyContent: 'center',
        paddingTop: MOBILE_SPACING,
        paddingBottom: MOBILE_SPACING,
        borderBottom: `1px solid ${theme.palette.border.primary}`,
        [theme.breakpoints.up('sm')]: {
            gridGap: `${ITEM_GAP}em`
        }
    },
    footerHeader: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontFamily: 'Inter',
        paddingTop: MOBILE_SPACING,
        paddingBottom: MOBILE_SPACING,
        [theme.breakpoints.only('sm')]: {
            paddingTop: TABLET_SPACING,
            paddingBottom: TABLET_SPACING
        },
    },
    divider: {
        width: '4em',
        height: '0.5em',
        backgroundColor: `${theme.palette.text.primary}`,
        margin: 'auto'
    },

}))

export default () => {
    const _classes = useStyles()

    return (
        <div data-testid={TEST_IDS.footerSite.companyLinks} className={_classes.companyLinks}>
            <Typography variant='h2' className={_classes.footerHeader}>
                Follow Along
            </Typography>
            <Divider className={_classes.divider}/>
            <nav aria-label='Social media links' className={_classes.socialMediaLinks}>
                <Linkedin
                    href='https://www.linkedin.com/company/the-coalition-studio/'
                    target='_blank'
                    rel='noopener noreferrer'
                    FontAwesomeIconProps={{size:"3x"}}
                    title='The Coalition Studio LinkedIn'
                />
                <Twitter
                    href='https://twitter.com/CoalitionGears'
                    data-testid={TEST_IDS.footerSite.twitterLink}
                    target='_blank'
                    rel='noopener noreferrer'
                    FontAwesomeIconProps={{size:"3x"}}
                    title='The Coalition Studio Twitter'
                />
            </nav>
        </div>
    )
}