import React, { useState, forwardRef } from 'react'
import isString from 'lodash/isString'
import TCMenuItem from 'tcweb-material-components/core/siteMenu/menuItem'
import get from 'tcweb-material-components/core/poly/get'
import classNames from 'classnames'

import { makeStyles, useTheme } from '@material-ui/styles'

import useLocation from '../../../hooks/useLocation'
import Link from '../../common/link'

const listStrings = (element) =>
	React.Children.map(element, (child) => {
		if (isString(child)) return child

		if (!get(child, 'props.children')) return child

		return React.cloneElement(child, {
			...child.props,
			children: listStrings(child.props.children)
		})
	})

	const useClasses = makeStyles(({ },) => ({
		root: {
			'&:hover, &:focus': {
				textDecoration: 'overline',
				textDecorationThickness: '10px'
			},
		},
		current: {
			textDecoration: 'overline',
			textDecorationThickness: '10px',
		}
	}))

const MenuItem = forwardRef(({ children, href, ...rest }, ref) => {
	const classes = useClasses()
	const [isActive, setIsActive] = useState(false)
	const { location } = useLocation()

	const isCurrent = href === location.pathname || `${href}/` === location.pathname

	const getProps = ({ isCurrent }) => {
		setIsActive(isCurrent)
		return null
	}

	return (
		<TCMenuItem
			classes={{ root: classNames(classes.root, isCurrent ? classes.current : null)}}
			{...rest}
			href={href}
			isActive={isActive}
			getProps={getProps}
			component={Link}
			ref={ref}
			aria-current={isCurrent ? "page" : null}
			fontFamily={'Commissioner'}
		>
			{listStrings(children)}
		</TCMenuItem>
	)
})

export default MenuItem
