import React, { useState } from 'react'
import { makeStyles, useTheme } from '@material-ui/styles'
import snakeCase from 'lodash/snakeCase'
import AppBar from '@material-ui/core/AppBar'
import MenuIcon from '@material-ui/icons/Menu'
import CloseIcon from '@material-ui/icons/Close'
import LaunchIcon from '@material-ui/icons/Launch'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import IconButton from 'tcweb-material-components/core/iconButton'
import Dialog from 'tcweb-material-components/core/dialog'
import classnames from 'classnames'

import useLogo from '../../../hooks/useLogo'
import MenuItem from './siteMenuItem'
import SocialLinks from './socialLinks'
import Link from '../../common/link'

const gutterStyles = {
  padding: '.5em 1.5em .5em 1.5em'
}
const useClasses = makeStyles(({ breakpoints, spacing, navigation, palette }) => ({
  logo: {
    height: '2em',
    [breakpoints.up('md')]:{
      height: '2.5em'
    }
  },
  logoMenuItem: { width: 'auto' },
  appBar: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: `${palette.background.primary.main}`,
    borderBottom: ({open}) => open ? 'none' : `1px solid ${palette.border.primary}`
  },
  dialogTitleProps: { margin: 0, paddingBottom: '.5em', alignItems: 'center' },
  noPadding: { padding: 0 },
  padding: { ...gutterStyles },
  closeButton: {
    paddingTop: 0,
    top: 0,
    border: 'none',
    '&:focus,&:hover': {
      color: palette.primary.main,
      background: 'none'
    }
  },
  dialogContent: {
    paddingBottom: '1em',
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5em',
    ...gutterStyles
  },
  menuLabels: {
    textTransform: 'uppercase'
  },
  socials: {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'flex-start',
    gap: '1em'
  },
  iconButton: { padding: 0 }
}))

const MobileModalMenu = ({ items }) => {
  const [open, setOpen] = useState(false)
  const classes = useClasses({open})
  const logo = useLogo('theCoalitionBlades')
  const currentTitle = "Main menu"

  const renderBrand = () => (
    <MenuItem href='/' className={classes.logoMenuItem}>
      <img src={logo} alt='The Coalition Studio' title="The Coalition Studio Home" className={classes.logo}/>
    </MenuItem>
  )

  const handleClick = () => {
    setOpen(!open)
  }

  const mobileMenuItems = (items) => {
    const itemList = items.map((item) => {
      if (item.url === '#') {
        return null
      }
      return (
        <Link href={item.url}>
          <Typography variant='h3' className={classes.menuLabels}>
            {item.label}
          </Typography>
        </Link>
      )
    })

    itemList.push(
      <Link href={process.env.GATSBY_FRANCHISE_SITE_URL}>
        <Typography variant='h3' className={classes.menuLabels}>
          Gears Of War <LaunchIcon />
        </Typography>
      </Link>
    )

    return itemList
  }

  return (
    <AppBar position='sticky' classes={{ root: classnames(classes.appBar, classes.padding) }}>
      {renderBrand()}
      <IconButton
        className={classes.iconButton}
        color='tertiary'
        onClick={handleClick}
        aria-label={currentTitle}
        aria-expanded={String(open)}
        aria-haspopup
      >
        <MenuIcon fontSize='large'/>
      </IconButton>
      <Dialog
        fullScreen
        color='secondary'
        open={open}
        labelledBy={snakeCase(currentTitle)}
        title={
          <>
            {renderBrand()}
            <Typography variant='srOnly'>
              <Typography variant='h2' aria-live='polite' id={snakeCase(currentTitle)}>
                {currentTitle}
              </Typography>
            </Typography>
          </>
        }
        onClose={handleClick}
        CloseButtonProps={{ component: IconButton, color: 'tertiary', 'aria-label': `Close ${currentTitle}`, classes:{ root: classnames(classes.closeButton, classes.iconButton) }}}
        CloseButtonComponent={<CloseIcon fontSize='large'/>}
        DialogTitleProps={{ className: classnames(classes.dialogTitleProps, classes.padding)}}
        PaperProps={{ className: classes.noPadding }}
        DialogContentProps={{ className: classes.dialogContent }}
      >
        {mobileMenuItems(items)}
        <Divider />
        <div className={classes.socials}>
          <Typography variant='h3' style={{fontFamily:'Inter', fontWeight: '500'}}>
            Follow Along
          </Typography>
          <SocialLinks home={false} />
        </div>
      </Dialog>
    </AppBar>
  )
}

export default MobileModalMenu