const colorManipulator = require('@material-ui/core/styles/colorManipulator')

const commonHeading = {
	textTransform: "capitalize"
}

const theme = {
	type: 'light',
	scrollbar: {
		style: 'none', // oneOf['none', 'default', 'primary']
	},
	navigation: {
		number: 2,
		tablet: {
			number: 1,
		},
		desktop: {
            number: 1,
		}
    },
    scratch: {
        height: 10,
        tablet: { height: 42 },
        desktop: { height: 42 }
    },
	palette: {
		button: {
			contained: {
				primary: {
					color: '#ffffff',
					background: '#B10204',
					borderColor: '#B10204',
					hover: {
						color: '#ffffff',
						background: colorManipulator.darken('#B10204', .2),
						borderColor: colorManipulator.darken('#B10204', .2)
					},
					focus: {
						color: '#ffffff',
						background: colorManipulator.darken('#B10204', .2),
						borderColor: colorManipulator.darken('#B10204', .2)
					}
				},
				secondary: {
					color: '#ffffff',
					background: '#000000',
					borderColor: '#757575',
					hover: {
						color: '#ffffff',
						background: '#757575',
						borderColor: '#757575'
					},
					focus: {
						color: '#ffffff',
						background: '#757575',
						borderColor: '#757575'
					}
				}
			},
			outline: {
				primary: {
					color: '#B10204',
					background: "transparent",
					borderColor: '#B10204',
					hover: {
						color: '#ffffff',
						background: colorManipulator.darken('#B10204', .2),
						borderColor: colorManipulator.darken('#B10204', .2)
					},
					focus: {
						color: '#ffffff',
						background: colorManipulator.darken('#B10204', .2),
						borderColor: colorManipulator.darken('#B10204', .2)
					}
				},
				secondary: {
					color: '#000000',
					background: "transparent",
					borderColor: '#000000',
					hover: {
						color: '#ffffff',
						background: '#000000',
						borderColor: '#000000'
					},
					focus: {
						color: '#ffffff',
						background: '#000000',
						borderColor: '#000000'
					}
				}
			}
		}
	},
	typography: { // TODO: When all sites have these styles, change at custom components theme level
        display1: { ...commonHeading },
        h1: { ...commonHeading, fontFamily: ['"Inter"', "Arial", "sans-serif"].join(", ") },
        h2: { ...commonHeading, fontFamily: ['"Inter"', "Arial", "sans-serif"].join(", ") },
        h3: { ...commonHeading, fontFamily: ['"Inter"', "Arial", "sans-serif"].join(", ") },
        h4: { ...commonHeading, fontFamily: ['"Inter"', "Arial", "sans-serif"].join(", ") },
        h5: { ...commonHeading, fontFamily: ['"Inter"', "Arial", "sans-serif"].join(", ") },
        h6: { ...commonHeading, fontFamily: ['"Inter"', "Arial", "sans-serif"].join(", ") },
        desktop: {
            display1: { ...commonHeading },
            h1: { ...commonHeading },
            h2: { ...commonHeading },
            h3: { ...commonHeading },
            h4: { ...commonHeading },
            h5: { ...commonHeading },
            h6: { ...commonHeading }
        }
    }
}

module.exports = theme
