import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { useStaticQuery, graphql } from 'gatsby'
import range from 'lodash/range'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import NativeSelect from '@material-ui/core/NativeSelect'
import classnames from 'classnames'
import Cookie from 'js-cookie'
import Button from 'tcweb-material-components/core/button'
import Dialog from 'tcweb-material-components/core/dialog'
import get from 'tcweb-material-components/core/poly/get'
import ThemeProvider from 'tcweb-material-components/core/themeProvider'
import { getFirstFocusableChild, isSamsungInternet } from 'tcweb-material-components/core/utilities/helpers'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import TEST_IDS from '../../constants/query-selectors'

import webstarterLogoWhite from '../../assets/images/logos/webstarter-white.svg'

const theme = {
    palette: {
        text: {
            primary: '#FFFFFF'
        }
    }
}

const useStyles = makeStyles(({ breakpoints, gutters, palette, accessibility: { highContrast } }) => ({
    container: {
        padding: gutters.page.xs,
        [breakpoints.up('md')]: { padding: gutters.page.md },
        [breakpoints.up('lg')]: { padding: gutters.page.lg }
    },
    paper: {
        padding: 0,
        overflowX: 'hidden',
        alignItems: 'center',
        boxShadow: '0 10px 20px 0px rgba(0, 0, 0, 0.50)',
        backgroundColor: '#000',
        backgroundSize: 'cover',
        background: 'url(https://cdn.gearsofwar.com/gearsofwar/sites/9/2020/03/gearsofwar_bg-5e5d453e43515.jpg) no-repeat center',
        [breakpoints.up('md')]: {
            backgroundImage: 'url(https://cdn.gearsofwar.com/gearsofwar/sites/9/2020/03/gearsofwar_bg-5e5d453e43515.jpg)'
        }
    },
    content: {
        overflow: 'visible',
        margin: 'auto',
        display: 'flex',
        flexFlow: 'column',
        alignItems: 'center',
        justifyContent: 'flex-end',
        textAlign: 'center',
        minHeight: '100%',
        background: 'linear-gradient(transparent 50%, rgba(0, 0, 0, 0.5) 60%)',
        padding: '0 !important',
        paddingTop: '100px',
        width: '100%',
        '@media (max-height: 450px)': {
            background: 'linear-gradient(transparent 5%, rgba(0, 0, 0, 0.5) 50%)'
        },
        [breakpoints.up('md')]: {
            background: 'radial-gradient(closest-side at center 70%, rgba(0, 0, 0, 0.95), rgba(0, 0, 0, 0.5) 70%, transparent 80%)',
            width: '50%'
        }
    },
    logo: {
        marginTop: '5vh',
        maxWidth: '300px',
        paddingLeft: '1em',
        paddingRight: '1em',
        marginBottom: 'auto',
        [breakpoints.up('md')]: { maxWidth: '100%' }
    },
    contentText: {
        color: '#fff',
        lineHeight: '1',
        textTransform: 'uppercase',
        fontStretch: 'condensed',
        fontWeight: '700',
        marginBottom: '5vh',
        marginTop: '1em',
        [breakpoints.up('md')]: {
            margin: '3vh'
        }
    },
    contentTextBlocked: {
        lineHeight: '1',
        textTransform: 'uppercase',
        fontStretch: 'condensed',
        fontWeight: '700',
        marginBottom: '150px',
        [breakpoints.up('md')]: { marginBottom: '200px' }
    },
    form: {
        '& .MuiInput-underline:after': {
            borderBottomColor: 'green'
        }
    },
    fieldContainer: {
        backgroundColor: palette.common.black,
        borderRadius: '6px',
        padding: '6px',
        display: 'grid',
        gridGap: '20px',
        gridTemplateColumns: '1fr',
        [breakpoints.up('md')]: {
            gridTemplateColumns: 'repeat(3, 1fr)'
        }
    },
    select: {
        color: palette.common.white,
        '& option': {
            color: palette.common.black
        }
    },
    icon: {
        [highContrast.mediaQuery('active')]: {
            color: 'WindowText'
        }
    },
    underline: {
        '&:before': {
            borderBottom: '1px solid',
            borderBottomColor: palette.primary.contrastText
        }
    },
    submit: {
        width: '75%',
        margin: '30px',
        [breakpoints.up('md')]: {
            width: '50%',
            margin: '3vh'
        }
    }
}))

const currentYear = new Date().getFullYear()

const AgeGate = () => {
    const data = useStaticQuery(graphql`
        {
            site {
                siteMetadata { title }
            }
            # rating: wpSiteRating(ratingACF: { locales: { in: ["en-us"] } }){
            #     ratingACF { ageGate }
            # }
        }
    `)

    const ageGate = get(data, 'rating.ratingACF.ageGate')
    const minAge = ageGate || parseInt(ageGate)
    const cookieDateOfBirth = Cookie.get('dob')
    let cookieAge = getAge(cookieDateOfBirth)

    if (cookieDateOfBirth) {
        let [_year, _month, _day] = unFormatDate(cookieDateOfBirth)
        cookieAge = getAge(new Date(_year, _month, _day))
    }

    const showAgeGate = !cookieAge || cookieAge < minAge

    const [isFormDirty, setIsFormDirty] = useState(false)
    const [open, setOpen] = useState(showAgeGate)
    const [blocked, setBlocked] = useState(cookieAge < minAge)
    const [day, setDay] = useState(1)
    const [month, setMonth] = useState(1)
    const [year, setYear] = useState(currentYear)
    const numOfDaysInMonth = moment(`${year}-${month}`, 'YYYY-MM').daysInMonth()

    const classes = useStyles()
    const isSamsung = isSamsungInternet()

    if (!minAge) {
        return null
    }

    const handleEntered = () => {
        disableBodyScroll()
    }

    const handleExiting = () => {
        enableBodyScroll()
        getFirstFocusableChild(document)
    }

    const handleClose = (e) => {
        e.preventDefault()

        const dayInt = parseInt(day)
        const monthInt = parseInt(month)
        const yearInt = parseInt(year)

        if (yearInt === currentYear) {
            setIsFormDirty(true)
            return
        }

        const dateOfBirth = new Date(yearInt, monthInt, dayInt)
        const age = getAge(dateOfBirth)
        const isUnderaged = age < minAge
        const expires = isUnderaged ? { expires: 1 } : { expires: 365 }
        Cookie.set('dob', formatDate(yearInt, monthInt, dayInt), expires)

        setBlocked(isUnderaged)
        setOpen(isUnderaged)
    }

    const roleProps = blocked ? { role: 'alert' } : ''

    useEffect(() => {
        const daysInMonth = moment(`${year}-${month}`, 'YYYY-MM').daysInMonth()

        if (day > daysInMonth) {
            setDay(1)
        }

        setIsFormDirty(false)
    }, [month, day, year])

    return (
        <Dialog
            data-testid={TEST_IDS.ageGate.container}
            fullScreen
            disableBackdropClick
            disableEscapeKeyDown
            open={open}
            onClose={handleClose}
            onEntered={handleEntered}
            onExiting={handleExiting}
            classes={{ root: classes.container }}
            PaperProps={{ className: classes.paper }}
            DialogContentProps={{ className: classes.content }}
            disableCloseButton
            aria-labelledby='age-gate-title'
        >
            <img className={classes.logo} src={webstarterLogoWhite} alt='Web Starter' />

            <Typography
                data-testid={TEST_IDS.ageGate.title}
                id='age-gate-title'
                variant='h1'
                {...roleProps}
                aria-live='assertive'
                className={classnames(classes.contentText, { [classes.contentTextBlocked]: blocked }, blocked ? 'h2' : 'h3')}
            >
                {blocked ? 'Access restricted' : 'Enter your date of birth'}
            </Typography>

            {!blocked && (
                <ThemeProvider theme={theme}>
                    {parseInt(year) === currentYear && isFormDirty && (
                        <div aria-live='assertive' aria-relevant='additions removals'>
                            <Typography className={classes.contentText} data-testid={TEST_IDS.ageGate.blockedMessage}>
                                The fields have default values. Please enter your Date of Birth.
                            </Typography>
                        </div>
                    )}
                    <form method='dialog' className={classes.form} autoComplete='off' onSubmit={handleClose}>
                        <div className={classes.fieldContainer}>
                            <NativeSelect
                                data-testid={TEST_IDS.ageGate.year}
                                required
                                error={parseInt(year) === currentYear && isFormDirty}
                                classes={{ root: classes.select, icon: classes.icon }}
                                inputProps={{ 'aria-label': 'Year' }}
                                autoFocus={!isSamsung}
                                value={year}
                                onChange={(e) => setYear(e.target.value)}
                            >
                                {range(currentYear - 1899).map((i) => (
                                    <option key={`year-${i}`} value={currentYear - i}>
                                        {currentYear - i}
                                    </option>
                                ))}
                            </NativeSelect>
                            <NativeSelect
                                data-testid={TEST_IDS.ageGate.month}
                                required
                                classes={{ root: classes.select, icon: classes.icon }}
                                inputProps={{ 'aria-label': 'Month' }}
                                value={month}
                                onChange={(e) => setMonth(e.target.value)}
                            >
                                {range(12).map((i) => {
                                    const monthName = moment('1975-01-01', 'YYYY-MM-DD').add(i, 'months').format('MMMM')
                                    return (
                                        <option key={monthName} value={i + 1}>
                                            {monthName}
                                        </option>
                                    )
                                })}
                            </NativeSelect>
                            <NativeSelect
                                data-testid={TEST_IDS.ageGate.day}
                                required
                                classes={{ root: classes.select, icon: classes.icon }}
                                inputProps={{ 'aria-label': 'Day' }}
                                value={day}
                                onChange={(e) => setDay(e.target.value)}
                            >
                                {range(numOfDaysInMonth).map((i) => (
                                    <option key={`day-${i}`} value={i + 1}>
                                        {i + 1}
                                    </option>
                                ))}
                            </NativeSelect>
                        </div>
                        <Button
                            data-testid={TEST_IDS.ageGate.submit}
                            type='submit'
                            variant='contained'
                            color='primary'
                            classes={{ root: classes.submit }}
                        >
                            Submit
                        </Button>
                    </form>
                </ThemeProvider>
            )}
        </Dialog>
    )
}

function getAge(inputDate) {
    var today = new Date()
    var birthDate = new Date(inputDate)
    var age = today.getFullYear() - birthDate.getFullYear()
    var m = today.getMonth() - birthDate.getMonth()

    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--
    }
    return age
}

function unFormatDate(inputString) {
    const [year, month, day] = inputString.split('-')
    return [year, month, day]
}

function formatDate(year, month, day) {
    if (month.length < 2) month = '0' + month
    if (day.length < 2) day = '0' + day

    return [year, month, day].join('-')
}

function daysInMonth(m, y) {
    // m is 0 indexed: 0-11
    switch (m) {
        case 1:
            return (y % 4 == 0 && y % 100) || y % 400 == 0 ? 29 : 28
        case 8:
        case 3:
        case 5:
        case 10:
            return 30
        default:
            return 31
    }
}

export default AgeGate
