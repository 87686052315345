import React from 'react'
import { makeStyles, useTheme } from '@material-ui/styles'
import SiteDesktopMenu from 'tcweb-material-components/core/siteMenu/desktop'
import Box from 'tcweb-material-components/core/box'
import loadable from '@loadable/component'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import useLogo from '../../hooks/useLogo'
import MenuItem from './components/menuItem'
import SiteMenuItem from './components/siteMenuItem'
import MobileModalMenu from './components/mobileModalMenu'

const ProfilePopup = loadable(() => import('./components/profilePopup'))
const SocialLinks = loadable(() => import('./components/socialLinks'))
const PushNotificationButton = loadable(() => import('../common/pushNotificationButton'))

const LOGIN_ENABLED = !!process.env.GATSBY_FEATURE_LOGIN_ON && JSON.parse(process.env.GATSBY_FEATURE_LOGIN_ON.toLowerCase()) === true
const PUSH_NOTIFICATION_ENABLED = !!process.env.GATSBY_FEATURE_PUSH_NOTIFICATION && JSON.parse(process.env.GATSBY_FEATURE_PUSH_NOTIFICATION.toLowerCase()) === true

const useClasses = makeStyles(({ breakpoints, accessibility: { highContrast }}) => ({
    root: {
        [highContrast.mediaQuery('active')]: {
            borderBottom: `1px solid WindowText`
        },
        borderBottom: `1px solid #2d2d2d`
    },
    logo: {
        height: '20px !important',
        marginTop: 0,
        [breakpoints.up('md')]: {
            height: '48px !important'
        }
    },
    logoLink: {
        display: 'flex',
        marginRight: '30px',
        width: 'initial',
        [breakpoints.up('lg')]: { marginRight: '50px' }
    },

    img: {
        marginBottom: '1em',
        borderRadius: '3%'
    },
    icon: {
        paddingBottom: '.1em',
        height: '30px'
    },
    iconTitle: { fontSize: '0.9em' }
}))

export default ({ menu: { desktop, mobile } }) => {
    const classes = useClasses()
    const logo = useLogo('theCoalition')
    const { breakpoints } = useTheme()
    const isMobile = useMediaQuery(breakpoints.down('sm'))
    const isDesktop = useMediaQuery(breakpoints.up('md'))
    const iconSize = isMobile ? '24px' : '30px'

    const renderBrand = () => (
        <MenuItem href={process.env.GATSBY_SITE_URL} aria-label='The Coalition Studio Home' classes={{ root: classes.logoLink }}>
            <img src={logo} alt='The Coalition Studio' title="The Coalition Studio Home" className={classes.logo}/>
        </MenuItem>
    )

    return (
        <>
            {isDesktop && (
                <SiteDesktopMenu
                    classes={{ root: classes.root}}
                    items={desktop}
                    renderBrand={renderBrand}
                    ButtonComponent={SiteMenuItem}
                    enableSkipMenuButton={true}
                    SkipMenuButtonProps={{ title: 'Skip to main content' }}
                    overflow={true}
                    flexEnd={true}
                >
                    <Box display='flex' alignItems='center'>
                        {PUSH_NOTIFICATION_ENABLED && (
                            <PushNotificationButton
                                marginRight={{ xs: '10px', sm: '20px' }}
                                FontAwesomeIconProps={{ style: { height: iconSize } }}
                            />
                        )}
                        {/* {LOGIN_ENABLED && <ProfilePopup />} */}
                        <SocialLinks home={true} />
                    </Box>
                </SiteDesktopMenu>
            )}

            {isMobile && ( <MobileModalMenu items={mobile} /> )}
        </>
    )
}
