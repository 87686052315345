import { useState, useEffect } from 'react'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import gearsOfWarLogoWhite from '../assets/images/logos/gearsOfWar-white.svg'
import gearsOfWarLogoBlack from '../assets/images/logos/gearsOfWar.svg'

import gearsOfWarOmenLogoWhite from '../assets/images/logos/gearsOfWarOmen-white.svg'
import gearsOfWarOmenLogoBlack from '../assets/images/logos/gearsOfWarOmen.svg'

import gearsOfWarLogoFullWhite from '../assets/images/logos/gearsOfWar-full-white.svg'
import gearsOfWarLogoFullBlack from '../assets/images/logos/gearsOfWar-full.svg'

import gears5LogoWhite from '../assets/images/logos/gears5-white.svg'
import gears5LogoBlack from '../assets/images/logos/gears5.svg'

import gearsEsportsLogoWhite from '../assets/images/logos/gearsEsports-white.svg'
import gearsEsportsLogoBlack from '../assets/images/logos/gearsEsports.svg'

import gearsPopLogoWhite from '../assets/images/logos/gearsPop-white.svg'
import gearsPopLogoBlack from '../assets/images/logos/gearsPop.svg'

import gearsTacticsLogoWhite from '../assets/images/logos/gearsTactics-white.svg'
import gearsTacticsLogoBlack from '../assets/images/logos/gearsTactics.svg'

import theCoalitionLogoFullWhite from '../assets/images/logos/theCoalition-full-white.svg'
import theCoalitionLogoFullBlack from '../assets/images/logos/theCoalition-full.svg'

import theCoalitionLogoBladesWhite from '../assets/images/logos/theCoalition-blades-white.svg'
import theCoalitionLogoBladesBlack from '../assets/images/logos/theCoalition-blades-black.svg'

import theCoalitionLogoTextWhite from '../assets/images/logos/theCoalition-text-white.svg'
import theCoalitionLogoTextBlack from '../assets/images/logos/theCoalition-text.svg'

import webstarterLogoWhite from '../assets/images/logos/webstarter-white.svg'
import webstarterLogoBlack from '../assets/images/logos/webstarter.svg'

import xboxGameStudiosBlack from '../assets/images/logos/xbox-game-studios-horizontal-black.svg'
import xboxGameStudiosWhite from '../assets/images/logos/xbox-game-studios-horizontal-white.svg'

import xboxLiveWhite from '../assets/images/logos/xboxLive-white.svg'
import xboxLiveBlack from '../assets/images/logos/xboxLive.svg'

import microsoftLogoColor from '../assets/images/logos/microsoft-logo.svg' // TODO: May need to split this if a11y fails

/**
 * Logo Names:
 *
 * Gears 5: gears5,
 * Gears Esports: gearsEsports
 * Gears of War: gearsOfWar / gearsOfWarFull (with omen)
 * Gears Pop!: gearsPop
 * Gears Tactics: gearsTactics
 * The Coalition Studio: theCoalition
 * Web Starter: webstarter
 *
 * Theme: light/dark
 */

const LOGOS = {
    gears5: { logoWhite: gears5LogoWhite, logoBlack: gears5LogoBlack },
    gearsOfWar: { logoWhite: gearsOfWarLogoWhite, logoBlack: gearsOfWarLogoBlack },
    gearsOfWarOmen: { logoWhite: gearsOfWarOmenLogoWhite, logoBlack: gearsOfWarOmenLogoBlack},
    gearsOfWarFull: { logoWhite: gearsOfWarLogoFullWhite, logoBlack: gearsOfWarLogoFullBlack },
    gearsEsports: { logoWhite: gearsEsportsLogoWhite, logoBlack: gearsEsportsLogoBlack },
    gearsPop: { logoWhite: gearsPopLogoWhite, logoBlack: gearsPopLogoBlack },
    gearsTactics: { logoWhite: gearsTacticsLogoWhite, logoBlack: gearsTacticsLogoBlack },
    theCoalition: {
        logoWhite: 'https://cdn.gearsofwar.com/gearsofwar/sites/9/2020/08/theCoalition-white-5f2b1be4bddeb.svg',
        logoBlack: 'https://cdn.gearsofwar.com/gearsofwar/sites/9/2020/08/theCoalition-5f2b1be957e4c.svg'
    },
    theCoalitionFull: { logoWhite: theCoalitionLogoFullWhite, logoBlack: theCoalitionLogoFullBlack },
    theCoalitionText: { logoWhite: theCoalitionLogoTextWhite, logoBlack: theCoalitionLogoTextBlack },
    theCoalitionBlades: { logoWhite: theCoalitionLogoBladesWhite, logoBlack: theCoalitionLogoBladesBlack },
    webstarter: { logoWhite: webstarterLogoWhite, logoBlack: webstarterLogoBlack },
    xboxGameStudios: { logoWhite: xboxGameStudiosWhite, logoBlack: xboxGameStudiosBlack },
    xboxLive: { logoWhite: xboxLiveWhite, logoBlack: xboxLiveBlack },
    splashDamage: {
        logoWhite: 'https://cdn.gearsofwar.com/gearsofwar/sites/9/2020/08/splashDamage-white-5f2c2964c0bc7.svg',
        logoBlack: 'https://cdn.gearsofwar.com/gearsofwar/sites/9/2020/08/splashDamage-5f2c295f7b2dc.svg'
    },
    microsoft: { logoWhite: microsoftLogoColor, logoBlack: microsoftLogoColor }
}

const DEFAULT_OPTIONS = {
    enableContrast: true,
    theme: null
}

const useLogo = (name = 'webstarter', options = DEFAULT_OPTIONS) => {
    const currentTheme = useTheme()
    const _options = { ...DEFAULT_OPTIONS, ...options }
    const _theme = _options.theme || currentTheme.palette.type
    const defaultLogo = _theme === 'light' ? LOGOS[name].logoBlack : LOGOS[name].logoWhite
    const [logo, setLogo] = useState(defaultLogo)
    const isHighContrast = useMediaQuery(currentTheme.accessibility.highContrast.mediaQuery('active'))
    const isHighContrastWB = useMediaQuery(currentTheme.accessibility.highContrast.prefersDark)
    const isHighContrastBW = useMediaQuery(currentTheme.accessibility.highContrast.prefersLight)

    useEffect(() => {
        if (_theme === 'light') {
            if ((isHighContrastWB || isHighContrast) && !isHighContrastBW && _options.enableContrast) {
                setLogo(LOGOS[name].logoWhite)
            } else {
                setLogo(LOGOS[name].logoBlack)
            }
        } else {
            if (isHighContrastBW && _options.enableContrast) {
                setLogo(LOGOS[name].logoBlack)
            } else {
                setLogo(LOGOS[name].logoWhite)
            }
        }
    }, [isHighContrastBW, isHighContrastWB, isHighContrast, _options.enableContrast, name, _theme])

    return logo
}

export default useLogo
