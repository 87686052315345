import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Image from 'tcweb-material-components/core/image'

import Link from '../common/link'
import { decodeText } from '../../utilities/helpers'
import useLogo from '../../hooks/useLogo'

const MOBILE_SPACING = '20px'

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '20px',
        marginBottom: '20px',
        [theme.breakpoints.up('md')]: { justifyContent: 'flex-end', flexDirection: 'row', }
    },
    link: {
        lineHeight: '1em',
        verticalAlign: 'middle',
        textTransform: 'initial',
        letterSpacing: 'initial',
        fontStretch: 'initial',
        fontWeight: 'initial',
        fontSize: '0.9em',
        fontFamily: 'Segoe UI, SegoeUI, "Helvetica Neue", Helvetica, Arial, sans-serif',
        [theme.breakpoints.up('md')]: { marginLeft: '1.33em' }
    },
    xboxList: {
        fontSize: '0.72em',
        listStyleType: 'none',
        display: 'flex',
        flexDirection: 'column',
        padding: 0,
        alignItems: 'center',
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
            width: '100%',
        }
    },
    linksList: {
        fontSize: '0.72em',
        listStyleType: 'none',
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        padding: 0,
        width: '100%',
        justifyContent: 'space-between',
        [theme.breakpoints.up('md')]: {
            justifyContent: 'flex-end'
        }
    },
    xboxLogo: { margin: 'auto' }
}))


const FooterMicrosoft = () => {
    const _classes = useStyles()
    const xboxGameStudiosLogo = useLogo('xboxGameStudios')

    return (
        <nav role='navigation' aria-label='Microsoft corporate links' className={_classes.root}>
           <ul className={_classes.xboxList}>
                <li>
                    <Link
                        href='https://www.xbox.com/xbox-game-studios'
                        title='Xbox Game Studios'
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        <Image height={25} src={xboxGameStudiosLogo} alt='' role='presentation' className={_classes.xboxLogo}/>
                    </Link>
                </li>
                <li>
                    <Link
                        classes={{ root: _classes.link }}
                        href='https://go.microsoft.com/fwlink/?linkid=2259814'
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        Consumer Health Privacy
                    </Link>
                </li>
            </ul>
            <ul className={_classes.linksList}>
                <li>
                    <Link classes={{ root: _classes.link }} href='https://go.microsoft.com/fwlink/?LinkId=521839' target='_blank' rel='noopener noreferrer'>
                        {decodeText('Privacy & cookies')}
                    </Link>
                </li>
                <li>
                    <Link classes={{ root: _classes.link }} href='https://www.microsoft.com/trademarks' target='_blank' rel='noopener noreferrer'>
                        Trademarks
                    </Link>
                </li>
                <li>
                    <Link
                        classes={{ root: _classes.link }}
                        href='https://www.microsoft.com/servicesagreement'
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        Terms of Use
                    </Link>
                </li>
                <li>
                    <span className={_classes.link}>© Microsoft {new Date().getFullYear()}</span>
                </li>
            </ul>
        </nav>
    )
}

export default FooterMicrosoft
