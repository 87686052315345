import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import get from 'tcweb-material-components/core/poly/get'
import filter from 'tcweb-material-components/core/poly/filter'

import HeaderSite from './site'

const Header = () => {

    const data = useStaticQuery(graphql`
        {
            SiteMenuDesktop: allWpSiteMenu (filter: {slug: {eq: "site-menu-desktop"}}){
                nodes { ...siteDesktopGraphqlFragment }
            }
            SiteMenuMobile: allWpSiteMenu(filter: {slug: {eq: "site-menu-mobile"}}) {
                nodes { ...siteMobileGraphqlFragment }
            }
        }
    `)

    // const FranchiseMenu = get(data, 'FranchiseMenu.nodes.0.menuItems.nodes')
    const menu = {
        desktop: get(data, 'SiteMenuDesktop.nodes.0.menuItems.nodes'),
        mobile: filter( i => get(i, 'parentId') == null,get(data, 'SiteMenuMobile.nodes.0.menuItems.nodes'))
    }

    return (
        <>
            <HeaderSite menu={menu} />
        </>
    )
}

export default Header
