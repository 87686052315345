import { useState, useEffect } from 'react'
import { globalHistory } from '@reach/router'
import { splitQueryString } from 'tcweb-material-components/core/utilities/helpers'

import { getLocaleFromPath } from '../utilities/locale'

const useLocation = () => {
    const initialState = {
        oldLocation: null,
        location: globalHistory.location,
        transitioning: globalHistory.transitioning,
        locale: getLocaleFromPath(globalHistory.location.pathname) || 'en-us',
        querystring: splitQueryString(globalHistory.location.href)
    }

    const [state, setState] = useState(initialState)

    useEffect(() => {
        const removeListener = globalHistory.listen(params => {
            const { location, transitioning } = params
            setState({
                ...state,
                oldLocation: state.location,
                location,
                transitioning,
                locale: getLocaleFromPath(location.pathname) || 'en-us',
                querystring: splitQueryString(location.href)
            })
        })
        return removeListener
    }, [state])

    return state
}

export default useLocation
