import React from 'react'
import TEST_IDS from '../../constants/query-selectors'

const GenericError = () => {
    return (
        <>
            <h1 data-testid={TEST_IDS.error.header}>Something went wrong</h1>
            <p data-testid={TEST_IDS.error.message} className='h5'>
                {' '}
                {'Please try again later'}{' '}
            </p>
        </>
    )
}

export default GenericError
